<template>
    <ion-page>
        <ion-content :fullscreen="true" class="ion-no-padding">
            <ion-item lines="full" mode="md">
                <ion-label position="stacked">{{ language.journeyTitle }}</ion-label>
                <ion-input autocomplete="off" :placeholder="language.title" v-model="journey.title" />
            </ion-item>
        </ion-content>
        <FixedFooter :buttons="footerButtons"/>
    </ion-page>
</template>

<script lang="ts">

import {IonContent, IonInput, IonItem, IonLabel, IonPage} from '@ionic/vue';
import {computed, defineComponent, ref} from "vue";
import FixedFooter from "@/components/FixedFooter.vue";
import router from "@/router";
import {execute} from "@/mixins/LoadingMixin";
import {createJourney, getJourney, updateJourney} from "@/services/JourneyService";
import {uuid4} from "@capacitor/core/dist/esm/util";
import {Journey} from "@/models/JourneyModel";
import {presentValidationAlert} from "@/services/Notify";
import {FooterButtonsBuilder} from "@/mixins/FooterButtonsBuilder";
import {useStore} from "vuex";

export default defineComponent({
    name: 'SelectJourneySetCategory',
    components: {
        IonContent,
        IonPage,
        IonItem,
        IonLabel,
        IonInput,
        FixedFooter
    },
    props: {
        journeySetId: {
            type: String,
            required: true
        },
        journeyId: {
            type: String,
            required: false
        }
    },
    async ionViewWillEnter() {
        await this.store.dispatch('app/setPageTitle', this.language.journeyCreationTitle);
        if (this.journeyId) {
            await execute(async () => {
                this.journey = await getJourney(this.journeyId as string);
            }, this.language.errors.loadingJourney);
        } else {
            this.journey.title = '';
        }
    },
    setup(props) {
        const store = useStore();
        const language = computed(() => store.getters['app/language']);
        const journey = ref({} as Journey);
        
        return {
            store,
            language,
            journey,
            footerButtons: computed(() => {
                return new FooterButtonsBuilder()
                    .addClearBackButton()
                    .addPrimaryNextButton(async () => {
                        if (journey.value.title) {
                            await execute(async () => {
                                const editingExisting = props.journeyId;
                                let journeyId = props.journeyId || '';
                                if (editingExisting) {
                                    await updateJourney(journey.value);
                                } else {
                                    journeyId = uuid4();
                                    await createJourney({
                                        id: journeyId,
                                        title: journey.value.title,
                                        journeySetId: props.journeySetId
                                    });
                                }
                                router.push({
                                    name: 'journeyCopyrightWarning',
                                    params: {journeySetId: props.journeySetId as string, journeyId: journeyId}
                                });
                            }, language.value.errors.savingJourney);
                        } else {
                            presentValidationAlert(language.value.validation.enterTitle);
                        }
                    }).create();
            })
        }
    }
});
</script>

<style scoped>
</style>